import React from 'react'
import { graphql } from 'gatsby'
import Layout from '../components/layout'
import Seo from '../components/seo'
import DateBlock from '../components/DateBlock'
import ReturnBack from '../components/ReturnBack'

const helpers = require('../components/helpers')

const previewTemplate = ({ data }) => {
  const { markdownRemark } = data
  const { frontmatter, html } = markdownRemark

  const pageTitle = `Ennakko: ${frontmatter.title}`

  return (
    <Layout>
      <Seo title={pageTitle} />
      <section>
        <div className={'page-block'}>
          <h3 className={'uk-h3'}>Ennakko</h3>
          <h2 className={'uk-h2'}>{frontmatter.title}</h2>
          <div className={'uk-text-medium'}>
            <DateBlock
              writtenOn={frontmatter.writtenOn}
              modifiedOn={frontmatter.modifiedOn}
            />
          </div>

          <dl className={'preview-description-list visible uk-text-medium'}>
            <dt className={''}>Ajankohta</dt>
            <dd>{helpers.outputDateTime(frontmatter.date)}</dd>
            <dt className={''}>Kokoontumispaikka</dt>
            <dd>{frontmatter.location}</dd>
            <dt className={''}>Alustaja(t)</dt>
            <dd>{frontmatter.speaker}</dd>
          </dl>

          <div className={'post-body'}>
            <div dangerouslySetInnerHTML={{ __html: html }} />
          </div>
        </div>

        <ReturnBack />
      </section>
    </Layout>
  )
}

export default previewTemplate

export const pageQuery = graphql`
  query($slug: String!) {
    markdownRemark(fields: { slug: { eq: $slug } }) {
      html
      fields {
        slug
      }
      frontmatter {
        date
        path
        title
        categories
        writtenOn
        modifiedOn
        participants
        speaker
        tags
        location
      }
    }
  }
`
